// import sayHello from './lib/sayHello.js';
import $ from 'jquery';
import maskPlugin from 'jquery-mask-plugin';
import fancybox from '@fancyapps/fancybox'; 
import './lib/previewYouTube.js';

// sayHello();

// Menu Btn 
$('.mnu_btn').on('click', function(e) {
  e.preventDefault;
  $(this).toggleClass('mnu_btn__active');
  $('.menu').toggleClass('mnu_btn__active');
  return false;
});

$('data-fancybox').fancybox({
  modal: true,
});

$('.scrollTo').on('click', function(event) {
  event.preventDefault();
  var id  = $(this).attr('href'),
  top = $(id).offset().top;
  $('body,html').animate({scrollTop: top}, 1500);
});

let headerBgHeight = 150;
$('[data-header]').each(function () {
  let sectHeight = $(this).height();
  headerBgHeight += sectHeight;
  console.log(headerBgHeight)
  $('.front-bg').height(headerBgHeight);
});


let allAccord = $('.accordeon-wraper .accordeon-text'),
		allAccordBtn = $('.accordeon-wraper .accordeon-btn');

$('.accordeon-btn').on('click', function () {
	allAccordBtn.removeClass('show');
	allAccord.slideUp().removeClass('show');
	$(this).addClass('show')
  $(this).next().slideDown().addClass('show');
  return false;
});

$('.show-next').on('click', function(){
	$(this).addClass('hidden');
	$(this).next().removeClass('hidden');
	return false;
});

// Mask Input

$(function() {
  let maskPhone = '+38 (000) 000-00-00';
  $('input[name=phone]').mask(maskPhone).focusout(function() {
    if ($(this).val().length < maskPhone.length) {
      $(this).val('');
    }});
  $('input[name=name]').mask('RRRRRRRRRRRRRRRR', {
    translation: {
      'R': {pattern: /[a-zA-Zа-яА-Я ]/}
    }});
});

// Range

$('.partsRange').on('input', function(){
  let rangeValue = $(this).val();
  $('.partsRangeText').text( rangeValue );
});

//Form 

var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function () {
  let th = $(this).find('.hidden');
  $.each(params, function(key, value){
    th.append('<input name="'+key+'" value="'+value+'" hidden>');
  });
});

$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.btn-submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field-input').addClass('empty-field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field-input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).removeClass('empty-field');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).addClass('empty-field');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.empty-field').parent().addClass('empty-field');
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty-field').parent().removeClass('empty-field');
      },1000);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {
      var phone = form.find('input[name=phone]').val();
      console.log(phone)
      btn.prop('disabled', true);

      $.ajax({
        type: 'POST',
        url: '/static/send_lead.php', //Change
        data: form.serialize()
      }).done(function() {
        // alert ('Ваши данные отправлены');
        // $.fancybox.close();
        // console.log("Data:"+d)

        let modalSuccess = '<div class="modalSuccess">'
        +'<div class="ms-icon"><svg class="icon icon-check"><use xlink:href="img/sprite.svg#icon-check"></use></svg></div>'
        +'<div class="ms-title title title--big">Спасибо!</div>'
        +'<div class="ms-text">Форма отправлена</div>'
        +'<div class="ms-text">Проверьте ваш телефон: <span class="ms-phone">'+phone+'</span></div>'
        +'</div>';

        $.fancybox.open({
          type: 'html',
          src: modalSuccess,
          afterShow: function(instance, current, e) {
            // console.log(instance, current.src, e);
            $('.modalSuccess').addClass('is-active');
          }
        });

        form.trigger('reset');
        btn.prop('disabled', false);

        // Отслеживание лидов
        fbq('track', 'Lead');
        // dataLayer.push({'event': 'send_request'});
        yaCounter52074648.reachGoal('form_order');
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.empty-field').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty > 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        fromSubmit();
        // form.submit();
      }
    });
  });
});



